import { Row, Tab } from 'react-bootstrap';
import TabPaneContent from './TabPaneContent';

interface TabPaneListProps {
  data: any;
  tab: string;
  expanded:boolean;
  setExpanded:Function;
  handleDropdownToggle:Function
}

const TabPaneList = ({ data, tab, expanded,setExpanded,handleDropdownToggle }: TabPaneListProps) => {
  return (
    <Tab.Pane eventKey={tab}>
      <Row>
        {data.map((item: any, index: number) => (
          <TabPaneContent data={item} key={index.toString()} expanded={expanded} setExpanded={setExpanded} handleDropdownToggle={handleDropdownToggle} />
        ))}
      </Row>
    </Tab.Pane>
  );
};

export default TabPaneList;
