export const menuData = [
  {
    heading: "What we do",
    dropdown: [
      {
        title: "Digitalisation",
        content: [
          {
            title: "Application Development",
            url: "/services/application-development",
            descr: "",
            links: [
              {
                text: "Web Application Development",
                link: "/services/web-app-development",
              },
              {
                text: "Native Mobile Application Development",
                link: "/services/mobile-app-development",
              },
              {
                text: "Hybrid App Development",
                link: "/services/hybrid-app-development",
              },
              {
                text: "PWA Development",
                link: "/services/pwa-development",
              },
            ],
          },
          {
            title: "Product Engineering",
            url: "/services/Product-engineering",
            descr: "",
            links: [
              {
                text: "Custom Product Engineering",
                link: "/services/custom-product-engineering",
              },
              {
                text: "Product Rescue",
                link: "/services/product-rescue",
              },
              {
                text: "Product Support & Maintenance",
                link: "/services/product-support-and-maintenance",
              },
              {
                text: "Legacy Software Modernization",
                link: "/services/legacy-software-modernization",
              },
            ],
          },
          {
            title: "IT Staff Augmentation",
            // title: "Team Augmentation",
            url: "/services/staff-augmentation",
            descr: "",
            links: [
              {
                // text: "Staff Augmentation",
                // link: "/services/team-staff-augmentation",
                text: "Full Stack Team Augmentation",
                link:"/services/staff-augmentation?id=9",
                section:true,
              },
              {
                // text: "Dedicated Team Augmentation",
                // link: "/services/dedicated-team-augmentation",
                text: "Specialized Skill Augmentation",
                link:"/services/staff-augmentation?id=10",
                section:true
              },
              {
                // text: "Technical Expertise Provision",
                // link: "/services/technical-expertise-provision",
                text: "Hybrid Team Augmentation",
                link:"/services/staff-augmentation?id=11",
                section:true
              },
            ],
          },
          {
            title: "Enterprise Solution Integration",
            url: "/services/enterprise-solution-integration",
            descr: "",
            links: [
              {
                text: "Workato",
                link: "/services/workato-integration-solutions",
              },
              {
                text: "Boomi",
                link: "/services/boomi-integration-solution",
              },
              {
                text: "MuleSoft",
                link: "/services/mulesoft-integration-solutions",
              },
              {
                text: "Salesforce",
                link: "/services/salesforce-development",
              },
              {
                text : "Microsoft Dynamics 365",
                link :"/services/dynamics-365-solutions"
              }
            ],
          },
        ],
      },
      {
        title: "Data & AI",
        content: [
          {
            title: "Artificial Intelligence",
            url: "/services/artificial-intelligence",
            descr: "",
            links: [
              {
                text: "Generative AI",
                link: "/services/generative-AI",
              },
              {
                text: "Conversational AI",
                link: "/services/conversational-artificial-intelligence",
              },
              {
                text: "Open GPT",
                link: "/services/open-GPT",
              },
              {
                text: "Vision Analytics",
                link: "/services/vision-analytics",
              },
              {
                text: "Computer Vision",
                link: "/services/computer-vision",
              },
            ],
          },
          {
            title: "Machine Learning",
            url: "/services/machine-learning",
            descr: "",
            links: [
              {
                text: "ML Model Development",
                link: "/services/ML-model-development-and-training",
              },
              {
                text: "Predictive Analysis",
                link: "/services/predictive-analysis",
              },
              {
                text: "Natural Language Processing",
                link: "/services/natural-language-processing",
              },
              {
                text: "Deep Learning Development",
                link: "/services/deep-learning-development",
              },
              {
                text: "MLOps Consulting",
                link: "/services/MLOps-consulting-services",
              },
            ],
          },
          {
            title: "Data Analytics",
            url: "/services/data-analytics",
            descr: "",
            links: [
              // {
              //   text: "Data Analysis as a Service",
              //   link: "/services/data-analysis-as-a-service",
              // },
              {
                text: "Data Visualization",
                link: "/services/data-visualization",
              },
              {
                text:'Data Warehousing',
                link:"/services/data-warehousing-solutions"
              },
              {
                text: "Power BI",
                link: "/services/Power-BI",
              },
              // {
              //   text: "BI Dashboard",
              //   link: "/services/BI-dashboard",
              // },
              // {
              //   text: "Automated Reporting",
              //   link: "/services/automated-reporting",
              // },
              {
                text:"Data Governance",
                link:"/services/data-governance-solutions"
              }
            ],
          },
          {
            title: "Intelligent Automation Solutions",
            url: "/services/intelligent-automation-solutions",
            descr: "",
            links: [
              {
                text: "Intelligent Automation Services",
                link: "/services/intelligent-automation-services",
              },
              {
                text: "RPA Development",
                link: "/services/robotic-process-automations",
              },
              {
                text: "Internet of Things Development",
                link: "/services/IoT-development-service",
              },
            ],
          },
        ],
      },
      {
        title: "Cloud",
        content: [
          {
            title: "Cloud Transformation",
            url: "/services/cloud-transformation-services",
            descr: "",
            links: [
              {
                text: "Cloud Architecture and Design",
                link: "/services/architecture-and-design",
              },
              {
                text: "Cloud Migration",
                link: "/services/cloud-migration-and-integration",
              },
              {
                text: "Cloud-Native Development",
                link: "/services/cloud-native-development",
              },
              {
                text: "Cloud Integration",
                link: "/services/cloud-integration-services",
              },
              {
                text: "Cloud Data & AI",
                link: "/services/cloud-data-and-AI/",
              },
            ],
          },
          {
            title: "DevOps As A Service",
            url: "/services/devOps-as-a-service",
            descr: "",
            links: [
              {
                text: "DevOps Assessment",
                link: "/services/devOps-assessment",
              },
              {
                text: "DevOps Automation",
                link: "/services/devOps-automation",
              },
              // {
              //   text: "DevOps Management",
              //   link: "/services/DevOps-management",
              // },
              {
                text: "CI/CD",
                link: "/services/continuous-integration-continuous-deployment",
              },
            ],
          },
          {
            title: "Maintenance",
            url: "/services/cloud-maintenance",
            descr: "",
            links: [
              {
                text: "Managed Support",
                link: "/services/managed-support",
              },
              {
                text: "Pay-As-You-Go Support",
                link: "/services/pay-as-you-go-support",
              },
              {
                text: "Emergency Support",
                link: "/services/emergency-support",
              },
            ],
          },
          {
            title: "Cloud Services",
            url: "/services/cloud-services",
            descr: "",
            links: [
              {
                text: "Kubernetes",
                link: "/services/Kubernetes",
              },
              {
                text: "Docker SWARM",
                link: "/services/Docker-SWARM",
              },
              {
                text: "AWS",
                link: "/services/aws",
                single: true,
              },
              {
                text: "Azure",
                link: "/services/azure",
                single: true,
              },
              {
                text: "GCP",
                link: "/services/GCP",
                single: true,
              },
            ],
          },
        ],
      },
      {
        title: "Consulting",
        content: [
          {
            title: "Digital Transformation",
            url: "/services/digital-transformation-consulting",
            descr: "",
            links: [
              {
                text: "Agile Transformation",
                link: "/services/agile-transformation",
              },
              {
                text: "Application Modernization",
                link: "/services/application-modernization",
              },
              {
                text: "RPA Implementation",
                link: "/services/rpa-implementation",
              },
              // {
              //   text: "BlockChain",
              //   link: "/services/blockchain",
              // },
            ],
          },
          {
            title: "Product Consulting",
            url: "/services/product-consulting",
            descr: "",
            links: [
              {
                text: "Product Strategy",
                link: "/services/product-strategy",
              },
              {
                text: "User Experience (UX) Design",
                link: "/services/user-experience-design",
              },
              {
                text: "Product Requirements Definition",
                link: "/services/product-requirements-definition",
              },
              {
                text:"QA as a Service",
                link:"/services/QA-as-a-Service"
              }
            ],
          },
          {
            title: "AI Consulting",
            url: "/services/artificial-intelligence-consulting",
            descr: "",
            links: [
              {
                text: "Strategy and Planning",
                link: "/services/strategy-and-planning",
              },
              {
                text: "Enterprise AI Solutions",
                link: "/services/Enterprise-AI-Solution",
              },
              {
                text: "Data Analysis and Modeling",
                link: "/services/data-analysis-and-modeling",
              },
            ],
          },
          {
            title: "DevOps Consulting",
            url: "/services/devOps-consulting",
            descr: "",
            links: [
              {
                text: "DevOps Assessment and Strategy",
                link: "/services/devops-assessment-and-strategy",
              },
              {
                text: "Cloud Adoption and Migration",
                link: "/services/cloud-adoption-and-migration",
              },
              {
                text: "CI/CD Pipeline",
                link: "/services/continuous-integration-and-continuous-delivery-pipeline",
              },
            ],
          },
        ],
      },
    ],
  },
];
