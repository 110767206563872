import useWindowSize from '@/hooks/useWindowSize';
import { useRouter } from 'next/router';
import React from 'react';


interface TabLinksProps {
  data: any;
  expanded: boolean;
  setExpanded: Function;
  handleDropdownToggle: Function;
}

const TabLinks = ({ data, expanded, setExpanded, handleDropdownToggle }: TabLinksProps) => {
  const router = useRouter();
  const [width] = useWindowSize();
  const redirectToPage = (e: any,data:any) => {
    // e.stopPropagation();
    // switch(data?.text){
    //   case 'Full Stack Team Augmentation':
    //     setNavCurrentId(9)
    //     break;
    //    case 'Specialized Skill Augmentation':
    //     setNavCurrentId(10)
    //     break;
    //    case 'Hybrid Team Augmentation':
    //     setNavCurrentId(11)
    //     break;
    //     default:setNavCurrentId(null)   
    //   }
    if (e.button !== 0) return; // Ignore right-clicks (button 2) and other clicks

    if (e?.ctrlKey){
      window?.open(data?.link, '_blank')
    }
    else{
      router.push(data?.link)
    }

    if (width < 767)
      setExpanded(!expanded)
    else {
      handleDropdownToggle(e, true)
    }
  
  }
  return (
    <li className='cursor-pointer'>
      {/* <Link prefetch={false} href={data.link}>{data.text}</Link> */}
      <a href={data.link} onClick={(e)=>redirectToPage(e,data)}>{data.text}</a>
    </li>
  );
};

export default React.memo(TabLinks);
