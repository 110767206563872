import { Nav } from 'react-bootstrap';

interface TabListProps {
  text: string;
  tab: string;
}

const TabList = ({ text, tab }: TabListProps) => {
  return (
    <Nav.Item>
      <Nav.Link eventKey={tab}>{text}</Nav.Link>
    </Nav.Item>
  );
};

export default TabList;
